<template>
  <dog-dialog
    ref="dogDialog"
    width="1200px"
    title="配置产品"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <div class="flex_row dialog_zone_scroll setUsersDialog">
      <div class="flex_1 flex_column">
        <dog-search
          ref="search"
          :config="searchConfig"
          @search="search"
        ></dog-search>
        <dog-table
          ref="dogTable"
          id="287a5cd8-219b-421f-88ea-6207a9f8ed90"
          row-key="id"
          :columns="tableColumns"
          :service="getList"
          :column-type="columnType"
          @selection-change="selectedData = $event"
        >
        </dog-table>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="setUsersDialog__selected">
        <h4>已选：</h4>
        <dog-scroll-box class="setUsersDialog__selected__tags">
          <el-tag
            size="small"
            closable
            @close="removeUser(user)"
            v-for="user in selectedData"
            :key="user.id"
            >{{ user.title }}</el-tag
          >
        </dog-scroll-box>
      </div>
    </div>
  </dog-dialog>
</template>

<script>
  import productService from '@/api/service/product';
  import classService from '@/api/service/class';

  export default {
    name: 'setUsersDialog',
    data() {
      return {
        searchConfig: [
          {
            type: 'input',
            label: '标题',
            key: 'title'
          }
        ],
        tableColumns: [
          { prop: 'title', label: '标题', width: 200 },
          {
            prop: 'mainImg',
            label: '主图',
            width: 200,
            render: (h, { props: { cur } }) =>
              cur &&
              cur.map((item) => (
                <el-image
                  style='height: 30px; margin-right: 10px;'
                  src={item}
                  fit='contain'
                  preview-src-list={cur}
                />
              ))
          },
          {
            prop: 'detailImg',
            label: '详情图',
            width: 200,
            render: (h, { props: { cur } }) =>
              cur &&
              cur.map((item) => (
                <el-image
                  style='height: 30px; margin-right: 10px;'
                  src={item}
                  fit='contain'
                  preview-src-list={cur}
                />
              ))
          },
          { prop: 'price', label: '价格', width: 100 },
          { prop: 'oldPrice', label: '原价', width: 100 },
          { prop: 'contact', label: '联系人', width: 100 },
          { prop: 'phone', label: '联系方式', width: 100 }
        ],
        columnType: [
          {
            type: 'selection',
            props: {
              reserveSelection: true
            }
          }
        ],
        selectedData: [],
        secondaryClassId: null,
        companyId: null
      };
    },
    methods: {
      openFrame({ id, companyId }) {
        this.secondaryClassId = id;
        this.companyId = companyId;
        this.$refs.dogDialog.open();
        classService
          .getSubClassProducts({ secondaryClassId: this.secondaryClassId })
          .then((res) => {
            for (let i = 0; i < res.length; i++) {
              this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
                res[i],
                true
              );
            }
            this.searchData();
          });
      },
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        params.companyId = this.companyId;
        return productService.getProductList(params).then((res) => {
          res.list.forEach((item) => {
            for (let i = 0; i < this.selectedData.length; i++) {
              // 所选数据重新赋值为列表数据，否则选中效果渲染不出来
              if (this.selectedData[i].id === item.id) {
                this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
                  this.selectedData[i],
                  false
                );
                this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
                  item,
                  true
                );
                break;
              }
            }
          });
          return res;
        });
      },
      removeUser(user) {
        this.$refs.dogTable.$refs.baseTable.$refs.elTable.toggleRowSelection(
          user,
          false
        );
      },
      confirm(done) {
        classService
          .setSubClassProducts({
            secondaryClassId: this.secondaryClassId,
            productIds: this.selectedData.map((item) => item.id)
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.selectedData = [];
        this.secondaryClassId = null;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @include b(setUsersDialog) {
    @include e(selected) {
      width: 300px;
      $h4_margin: 10px;
      $h4_height: 16px;
      h4 {
        margin-top: $h4_margin;
        margin-bottom: $h4_margin;
        line-height: $h4_height;
      }
      @include e(tags) {
        height: calc(100% - #{$h4_height} - #{$h4_margin} - #{$h4_margin});
      }
      /deep/ .el-tag {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
</style>
