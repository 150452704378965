import baseService from '../baseService';
const request = baseService('product');
import { stringNumToBoolean } from '@/utils/methods/common';

export default {
  getProductList(params) {
    return request('/getProductList', params, 'get').then((res) => {
      res.list.forEach((item) => {
        item.enable_cn = stringNumToBoolean(item.enable);
        item.recommend_cn = stringNumToBoolean(item.recommend);
      });
      return res;
    });
  },
  quickOperateProduct(params) {
    return request('/quickOperateProduct', params, 'put');
  },
  deleteProducts(params) {
    return request('/deleteProducts', params, 'delete');
  },
  addProduct(params) {
    return request('/addProduct', params, 'post');
  },
  editProduct(params) {
    return request('/editProduct', params, 'put');
  },
  getProductDetail(params) {
    return request('/getProductDetail', params, 'get');
  },
  setProductModel(params) {
    return request('/setProductModel', params, 'post');
  },
  getProductModel(params) {
    return request('/getProductModel', params, 'get');
  },
  setProductClass(params) {
    return request('/setProductClass', params, 'post');
  },
  getProductClass(params) {
    return request('/getProductClass', params, 'get');
  },
  syncProductModel(params) {
    return request('/syncProductModel', params, 'post');
  },
  batchOperateProduct(params) {
    return request('/batchOperateProduct', params, 'put');
  }
};
